$topNavHeight: 56px;
$widthBreakpoint: 800px;

$outlineBorderRadius: 5px;

$headerHeight: 56px;
$footerHeight: 56px;
$footerShadowHeight: 8px;

$lockHeaderHeight: 71px;

$inputHeight: 38px;
$inputRadius: 5px;
$inputPadding: 7.5px;

@mixin atContext($context, $addition) {
  @at-root #{selector-replace(&, $context, $context + $addition)} {
    @content;
  }
}
