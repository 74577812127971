@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.viewOnly {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5f5f5f;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  display: flex;

  > img {
    width: 40px;
    height: 40px;
    &.hover {
      display: none;
    }
  }

  > div {
    flex-direction: column;
  }

  .unlock {
    display: none;
  }
}

.complete {
  cursor: pointer;
  &:hover {
    background: #d9d9d9;
    border-radius: 5px;
    padding: 5px;
    .vo {
      display: none;
    }
    .unlock {
      display: initial;
    }
    > img {
      display: none;
      &.hover {
        display: initial;
      }
    }
  }
}

.completed {
  color: #2f8738;
  font-weight: bold;
}
