.container {
  display: flex;
  align-items: center;
}

.input {
  margin: 0;
}

.stageLabel {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin-right: 5px;
}
