html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  background-color: rgb(241, 242, 242);
  height: inherit;
  width: inherit;
  font-family: Helvetica;
}

[id='root'] {
  overflow: auto;
  width: 100%;
}

@media all and (max-width: 800px) {
  .top-nav {
    display: none;
  }
}

@media all and (min-width: 800px) {
  .top-nav {
    flex: 1 0px;
  }
}

.alertStyle {
  background-color: #008284;
  display: flex;
  border-radius: 5px;
  border: 1px solid #fff;
  position: relative;
  top: 60px;
}

.alertStyleError {
  background-color: #c74846;
}

.alertStyleSuccess {
  background-color: #2f8738;
}

.alertMessage {
  margin: 10px;
  padding-top: 2.5px;
  color: #fff;
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.alertStyle img {
  height: 28px;
  width: 28px;
  margin: 10px;
}

.loading {
  background: rgba(0, 0, 0, 0.5);
  transition-property: background, backdrop-fiter, visibility;
  transition-duration: 500ms;
  transition-timing-function: linear;
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.hidden {
    visibility: hidden;
    backdrop-filter: none;
    background: rgba(0, 0, 0, 0);
    transition-duration: 250ms;
    div {
      width: 0;
      transition-duration: 250ms;
    }
  }
  div {
    width: 400px;
    transition-property: width;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    max-width: 50%;
    img {
      width: 100%;
    }
  }
}
