@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.leftNav {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .quickNav {
    border: 1px solid #{map-get($colors, 'blue')};
    border-radius: 10px;
    width: 200px;
    margin: 0;
    background: map-get($colors, 'white');
    list-style-type: none;
    padding: 5px 10px;
    min-height: 181px;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;

    > li {
      border-radius: 5px;
      text-align: left;
      padding: 5px;
      color: map-get($colors, 'blue');
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
      cursor: pointer;
      &:not(.removedHeader) {
        @include color('white', $hover: true);
      }

      &.removedHeader {
        color: map-get($colors, 'red');
        margin-top: 10px;
        border-radius: 0;
        border-bottom: 1px solid #{map-get($colors, 'red')};
        cursor: default;
      }

      &.selected {
        @include color('blue', $hover: true, $text: true);
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      }

      &.completed {
        color: map-get($colors, 'green');
      }

      &.completed.selected {
        @include color('green', $hover: true, $text: true);
      }

      &.removed {
        color: map-get($colors, 'red');
      }

      &.removed.selected {
        @include color('red', $hover: true, $text: true);
      }
    }
  }
}

.addButton {
  $add: &;
  position: relative;
  cursor: pointer;
  margin-top: 20px;

  img {
    width: 155px;
    height: 36px;
    content: url('../../assets/images/addEvidenceButton.png');

    @include atContext($add, ':hover') {
      content: url('../../assets/images/addEvidenceHover.png');
    }

    @include atContext($add, '.hazard') {
      content: url('../../assets/images/addHazard.png');

      @include atContext($add, ':hover') {
        content: url('../../assets/images/addHazardHover.png');
      }
    }
  }

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 155px;
    height: 36px;
    cursor: pointer;
  }
}
