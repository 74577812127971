@import '../../styles/globals.scss';

.dateTime {
  font-size: 16px;
  line-height: 18px;

  span {
    font-weight: bold;
    margin-left: 1ch;
  }
}

.header {
  font-size: 14px;
  font-style: italic;
}

.incidentCard {
  $hoverHere: &;
  width: 490px;
  margin-top: 40px;
  cursor: pointer;
  position: relative;
  color: #000000;

  .updated {
    display: flex;
    flex-direction: column;
    position: relative;
    right: 0;
    text-align: right;
    &, .header {
      font-size: 16px;
    }
  }

  .content {
    width: 490px;
    background: #ffffff;
    border-radius: 10px 10px 20px 40px;
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 60px;

    @include atContext($hoverHere, ':hover') {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
    }

    .iconRow {
      display: flex;
      justify-content: center;

      svg {
        height: 40px;
        width: 40px;
        margin: 0px 8px;
      }
    }

    .group, .project {
      padding: 0px 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: italic;
      text-align: center;
    }

    .group {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }

    .project {
      font-size: 16px;
      line-height: 18px;
    }

    .firstReported {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 12px;
      bottom: 0;
      text-align: right;
      height: 60px;
      justify-content: center;
    }

    .profileBadge {
      position: absolute;
      left: 12px;
      bottom: 0;
      height: 60px;
    }
  }
}

.severity {
  width: 301px;
  height: 43px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  position: absolute;
  padding-left: 40px;
  top: -21.5px;
  z-index: 50;
  left: -4px;
  text-align: left;
  display: flex;
  align-items: center;

  &.green {
    background: #2f8738;
    color: #fff;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
  &.yellow {
    background: #ffcc00;
    color: #000;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  }
  &.red {
    background: #c74846;
    color: #fff;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.attachment {
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 50px;
  img {
    width: 100%;
    height: 100%;
  }
}

.description {
  overflow: hidden;
  text-align: left;
  width: 100%;
  padding: 0 25px;
  margin: 10px 0;
  max-height: 32px;
  line-height: 16px;
  text-overflow: ellipsis;
}

.stages {
  width: calc(100% - 2 * 67.5px);
  height: 43px;
  margin: 0 67.5px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #008284;

  .circles {
    width: 36px;
    height: 36px;
    background: #fff;
    position: relative;
    top: -15px;

    .notStarted, 
    .started,
    .pastDue,
    .complete {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > img {
        object-fit: contain;
        width: 20px;
      }
    }
    .notStarted {
      background: #5f5f5f;
    }
    .started {
      background: #008284;
    }
    .pastDue {
      background: #c74846;
    }
    .complete {
      background: #2f8738;
    }

    .stageSelected {
      position: absolute;
      left: 50%;
      top: 100%;
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      transform: translateX(-50%);
    }
  }
}
