@import '../../../styles/globals.scss';

.input.input {
  flex-direction: row;
  align-items: center;
  margin: 0;
  span {
    margin: 0;
  }
}

.container {
  $container: &;
  position: relative;
  order: -1;
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
  font-size: 1em;
  position: relative;

  > div {
    display: flex;
    justify-content: flex-start;
  }

  input {
    opacity: 0;
    cursor: pointer;
    width: 38px;
    height: 38px;
    position: absolute;
    margin: 0;
    &:disabled {
      cursor: not-allowed;
    }
    &:checked ~ .checkbox {
      background-color: #fff;
    }
  }

  .checkbox {
    top: 0;
    left: 0;
    height: 38px;
    width: 38px;
    border: 2px solid #949494;
    border-radius: 5px;
    background-color: #fff;
    display: grid;
    align-items: center;
    padding: 5px;

    @include atContext($container, ':hover') {
      background-color: #d9d9d9;
    }

    .input.disabled & {
      border: none;
      background-color: #f1f2f2;
      cursor: not-allowed;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
