@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.container {
  position: relative;

  :global(.textbox) {
    width: 100%;
  }

  :global(.textbox).hasSuggestions {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.suggestions {
  position: absolute;
  top: $inputHeight - 1;
  width: 100%;
  border: 1px solid map-get($colors, 'grey-stroke');
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 $inputRadius $inputRadius;
  z-index: 4;

  .item {
    padding: 8px;
    text-align: left;
    background-color: map-get($colors, 'white');
    cursor: pointer;

    &:last-of-type {
      border-bottom-left-radius: $inputRadius;
      border-bottom-right-radius: $inputRadius;
    }

    &.active {
      background-color: map-get($colors, 'grey-bkgd');
    }
  }
}

.error {
  color: red;
}
