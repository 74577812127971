.container {
  display: flex;
  flex-direction: row;

  .recurring {
    padding: 0;
    > ul {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .time {
    padding: 0;
    margin-top: 0;
    input:global(.textbox) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
