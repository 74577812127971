.happenToWorker {
  display: flex;
  flex-flow: row;
}

.toggleText {
  font-weight: 600;
  font-size: 18px;
  color: #ff0000;
  align-self: center;
}
