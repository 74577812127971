/* eslint-disable */
// prettier-ignore
$colors: (
  "green":        hsl(126, 48%, 36%),  //#2F8738
  "green-hover":  hsl(126, 48%, 26%),  //#226128
  "green-light":  hsl(126, 27%, 87%),  //#D5E7D7
  "blue":         hsl(181, 100%, 26%), //#008284
  "blue-hover":   hsl(181, 100%, 18%), //#005D5E
  "blue-light":   hsl(180, 34%, 85%),  //#CCE6E6
  "red":          hsl(1, 54%, 53%),    //#C74846
  "red-hover":    hsl(1, 48%, 43%),    //#A13A38
  "red-light":    hsl(0, 54%, 91%),    //#F4DADA
  "yellow":       hsl(48, 100%, 50%),  //#FFCC00
  "yellow-hover": hsl(48, 100%, 35%),  //#B38F00
  "yellow-light": hsl(49, 100%, 93%),  //#FFF8D9
  "grey-stroke":  hsl(0, 0%, 58%),     //#949494
  "grey-bkgd":    hsl(240, 4%, 95%),   //#F1F2F2
  "black":        hsl(0, 0%, 0%),      //#000000
  "grey":         hsl(0, 0%, 37%),     //#5F5F5F
  "grey-hover":   hsl(0, 0%, 17%),     //#2B2B2B
  "white":        hsl(0, 0%, 100%),    //#FFFFFF
  "white-hover":  hsl(0, 0%, 85%)      //#D9D9D9
);

$textForColor: (
  'blue': 'white',
  'green': 'white',
  'red': 'white',
  'white': 'black',
  'grey': 'white',
  'redOutline': 'red',
  'greenOutline': 'green',
  'blueOutline': 'blue'
);

$borderForColor: (
  'blue': 'white',
  'green': 'white',
  'red': 'white',
  'white': 'grey-stroke',
  'grey':  'grey-hover',
  'redOutline': 'red',
  'greenOutline': 'green',
  'blueOutline': 'blue'
);

$hoverForColor: (
  'blue': 'blue-hover',
  'green': 'green-hover',
  'red': 'red-hover',
  'grey': 'grey-hover',
  'white': 'white-hover',
  'redOutline': 'red-light',
  'greenOutline': 'green-light',
  'blueOutline': 'blue-light'
);

@mixin color($name, $prop: 'background-color', $options...) {
  $keys: keywords($options);
  $textColor: map-get($textForColor, $name);
  
  @if map-get($keys, 'hover') {
    &:hover {
      $hoverColor: map-get($hoverForColor, $name);
      #{$prop}: map-get($colors, $hoverColor);
    }
  }

  @if map-get($keys, 'disabled') {
    &:disabled {
      #{$prop}: map-get($colors, '#{$name}-light');
    }
  }

  @if map-get($keys, 'text') {
    color: map-get($colors, $textColor);
  }

  @if map-get($keys, 'border') {
    $borderColor: map-get($borderForColor, $name);
    border-color: map-get($colors, $borderColor);
  }

  @if map-get($keys, 'svgFillAsText') {
    svg {
      fill: map-get($colors, $textColor);
    }
  }

  @if map-get($keys, 'svgStrokeAsText') {
    svg {
      stroke: map-get($colors, $textColor);
    }
  }

  #{$prop}: map-get($colors, $name);
}

@mixin colorHover($name, $prop: 'background-color') {
  @include color($name, $hover: true);
}

@mixin colorStyling($keys...) {
  &.green {
    @include color('green', $keys...);
  }
  &.blue {
    @include color('blue', $keys...);
  }
  &.red {
    @include color('red', $keys...);
  }
  &.white {
    @include color('white', $keys...);
  }
  &.grey {
    @include color('grey', $keys...);
  }
  &.redOutline {
    @include color('redOutline', $keys...);
  }
  &.greenOutline {
    @include color('greenOutline', $keys...);
  }
  &.blueOutline {
    @include color('blueOutline', $keys...);
  }
}

// TODO: Get rid of the above and rename this to colorStyling?
@mixin globalColorStyling($keys...) {
  &:global(.green) {
    @include color('green', $keys...);
  }
  &:global(.blue) {
    @include color('blue', $keys...);
  }
  &:global(.red) {
    @include color('red', $keys...);
  }
  &:global(.white) {
    @include color('white', $keys...);
  }
  &:global(.grey) {
    @include color('grey', $keys...);
  }
  &:global(.redOutline) {
    @include color('redOutline', $keys...);
  }
  &:global(.greenOutline) {
    @include color('greenOutline', $keys...);
  }
  &:global(.blueOutline) {
    @include color('blueOutline', $keys...);
  }
}

$topNavColor: #2f8738;
