.loginForm {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loginForm button {
  font-size: 1.5em;
  height: 50px;
}

.loginForm .widget {
  min-width: 320px;
  margin: 0;
}

.login h2 {
  text-align: center;
  font-weight: normal;
}

.login--verbage {
  text-align: center;
}

.login a,
.login-registration a {
  text-decoration: none;
  color: #58a15b;
  font-weight: 700;
}

.login a:hover,
.login-registration a:hover {
  text-decoration: none;
  color: #437a45;
  font-weight: 700;
}

.loginForm--forgotPassword {
  text-align: right;
  padding-top: 0px;
  margin-top: 0px;
}

.loginForm--verbage {
  text-align: center;
  padding-top: 0px;
  margin-top: 0px;
}

@media all and (max-width: 800px) {
  .widget {
    display: block;
    flex: 1 auto;
    margin-left: 0px;
    width: 90vw;
  }
}

.login-registration {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0px;
  margin: 0px;
  background-image: linear-gradient(-225deg, #e3fdf5 0%, #ffe6fa 100%);
  height: 100%;
}

.login-registration h2 {
  margin-top: 50px;
}

.login-registration h3 {
  margin: 10px 0 0 0;
  padding: 0;
}

.login-registration p {
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.login-registration img {
  width: 30vw;
  margin-left: 5vw;
  margin-top: 10px;
}

.login-registration .form-fields--split {
  padding-bottom: 0px;
}

.login-registration .widget {
  display: block;
  margin: 0 0 10px;
  width: 100%;
  background-color: #fff;
}

.login-registration footer {
  width: 100%;
}

.login-registration footer img {
  object-fit: contain;
  width: 60%;
  margin: 0 20%;
}

.login-iPhoneBadge {
  display: inline-block;
  overflow: hidden;
  background: url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-10-19&kind=iossoftware&bubble=ios_apps)
    no-repeat;
  width: 135px;
  height: 40px;
  position: fixed;
  left: 125px;
}

.login-androidBadge {
  width: 170px;
  position: fixed;
  left: 100px;
}

.login-messageHeader {
  text-align: center;
  color: #58a15b;
}

.login-modalLogo {
  width: 98%;
}

.google-login-button {
  margin-top: 0px !important;
  height: inherit !important;
  background-color: white;
  color: #008284 !important;
  box-shadow: none !important;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5) !important;
  font-size: 24px !important;
  line-height: 28px !important;
  border: 2px solid #008284 !important;
  border-radius: 5px !important;
  position: relative;
  top: -52px;
}

.microsoft-login-button button {
  color: #008284 !important;
  border: 2px solid #008284 !important;
}

.microsoft-login-button img {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}
