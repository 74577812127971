@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.assignee {
  position: relative;
  $assignee: &;

  > div {
    display: flex;
    padding: 0 5px;
    @include atContext($assignee, '.hasOptions') {
      cursor: pointer;
      &:hover {
        background: map-get($colors, 'white-hover');
      }
    }
  }
  
  .profileBadge div:global(.name) {
    order: -1;
    span {
      font-style: normal;
    }
  }

  > ul {
    list-style: none;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0;
    padding: 0;
    height: fit-content;
    width: 100%;
    position: absolute;
    z-index: 500;
    border: 1px solid;
    border-top: none;
    right: 0px;
    box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);
    cursor: pointer;
    @include color('white', $border: true);

    > li {
      height: 38px;
      text-align: left;
      padding-left: 11px;
      &:hover {
        background: map-get($colors, 'white-hover');
      }

      > div {
        padding: 10px 0;
        color: map-get($colors, 'blue');
        text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
        font-size: 16px;
        line-height: 18px;
      }
    }

    @include atContext($assignee, ':not(.shown)') {
      display: none;
    }
  }
}

.dropdown {
  margin: 12.5px 10px 10px;
  
  > img {
    width: 10px;
    height: 5px;
  }
}
