.button {
  margin: 0;
}

:global(.ReactTable) .pagination {
  align-items: center;
  justify-content: center;

  :global(.-previous), :global(.-center), :global(.-next) {
    flex: initial;
  }
}

.dateFilter {
  width: 100%;
}
