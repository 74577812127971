form .textbox {
  width: 96%;
}

.incidentBasicsForm {
  margin-bottom: 40px;
}

/* form .dropdown,
form .multiselect {
  position: absolute;
} */

.form-fields {
  padding: 10px;
}

.form-fields--split {
  padding: 10px;
  display: flex;
  flex-flow: row;
}

.form-fields--rightSide {
  width: 47%;
  margin-left: 3%;
}

.form-fields--leftSide {
  width: 50%;
}

.form-error {
  margin-top: 20px;
  display: block;
  color: rgb(204, 51, 0);
  text-align: left;
}

.form-warning {
  display: block;
  color: rgb(255, 204, 0);
  text-align: left;
}

.form-error--multiselect {
  margin-top: 40px;
  display: block;
  color: rgb(204, 51, 0);
  text-align: left;
}

.form-link {
  text-decoration: none;
  height: 24px;
  margin-top: 5px;
}

.form-fields--sameLine {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.form-fields--sameLine .input {
  width: 47%;
  margin-right: 22px;
}

.form-fields--dateTime {
  display: flex;
  flex-flow: row nowrap;
}

.formHeading {
  border-bottom: 1px solid gray;
  width: 96%;
  margin-left: 10px;
  margin-bottom: 15px;
}

.formHeading h2,
.formHeading h3 {
  margin-bottom: 0px;
}

form h4 {
  margin-bottom: 0px;
}

.required {
  color: red;
  text-align: left;
}

.form-fields--logo {
  flex-direction: column;
  text-align: center;
  width: 50%;
  margin: auto;
}

.form-footer--buttons {
  display: flex;
  flex-flow: row nowrap;
}

.form-fields img {
  max-width: 300px;
}

.form-fields--combinationQuestion {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.form-fields--combinationQuestionLeftSide {
  border-right: solid 1px #000;
  padding-right: 60px;
}

.form-fields--combinationQuestionRightSide {
  padding-left: 60px;
}

.form-fields--attachmentsLeftSide {
  width: 40%;
}

.form-fields--attachmentsRightSide {
  width: 60%;
}

.form-fields--combinationQuestion .textbox {
  width: 209px;
}

.form-fields--year .textbox {
  width: 148px;
}

.form-fields--website {
  margin-top: 20px;
}

.form-fields--address {
  margin-top: 20px;
  margin-left: 5px;
}

.newLocationForm .form-fields--address {
  margin-top: 20px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.form-fields--hoverButton {
  position: absolute;
  top: 190px;
  margin-left: 39px;
  display: none;
}

.form-fields--attachmentsLeftSide:hover .form-fields--hoverButton {
  display: block;
}

.form-fields--attachmentsLeftSideImage {
  height: 190px;
}

.form-fields--attachmentsLeftSide img {
  max-width: 100%;
  object-fit: contain;
}

.customTheme {
  color: #fff !important;
  background-color: #595959 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  width: 300px;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: scroll !important;
}

.errorTheme {
  color: #fff !important;
  background-color: #804040 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  width: 300px;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
}

.label-info {
  margin-left: 10px;
}

.files {
  display: flex;
  flex-flow: row wrap;
}

[data-for='mainAddress.stateName'],
[data-for='state'] {
  margin: 0px;
  margin-top: 4px;
}

.google-login-button {
  margin-top: inherit;
  height: initial;
  background-color: #2f8738;
}

.noPadding {
  padding: 0px;
  margin: 0px;
}

form .downloader {
  width: 75px;
  height: 75px;
}

form .deleteAttachment {
  min-width: 20px;
  width: 20px;
  height: 20px;
  min-height: 20px;
  left: 57px;
  top: -8px;
}
