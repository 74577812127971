@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.container {
  display: flex;
  flex-direction: row;

  :global(.textbox) {
    flex-grow: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .currencyUnit {
    border: 1px solid map-get($colors, 'grey-stroke');
    background-color: map-get($colors, 'grey-bkgd');
    border-right: 0;
    border-radius: $inputRadius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: $inputHeight;
    line-height: $inputHeight;
    padding: 0 $inputPadding;
    &.disabled {
      border-color: map-get($colors, 'grey-bkgd');
    }
  }
}
