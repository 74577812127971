@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

ul.multiSelect {
  $multiSelect: &;
  min-height: 38px;
  max-height: fit-content;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  border-radius: 5px;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 155px;
  background: map-get($colors, 'white');
  cursor: pointer;
  position: relative;
  width: 100%;

  &.permDropdown {
    min-width: 0;
  }

  div.right {
    position: absolute;
    right: 5px;
    > img {
      content: url('../../../assets/images/downChevron.png');
      width: 10px;
      height: 5px;
      margin: 0;
      padding: 0;
      position: relative;
      top: -2.5px;

      @include atContext($multiSelect, '.disabled') {
        visibility: hidden;
      }
      @include atContext($multiSelect, '.showOptions') {
        content: url('../../../assets/images/Chevron.png');
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: map-get($colors, 'grey-bkgd');
    border: none;
  }

  &.showOptions {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  > li.select {
    display: inline-flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    text-align: left;
    padding: 7.5px 0;
    @include colorHover('white');

    @include atContext($multiSelect, '.disabled') {
      filter: opacity(50%);
    }
    @include atContext($multiSelect, '.showOptions') {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @include atContext($multiSelect, '.permDropdown') {
      justify-content: center;
    }

    > span {
      color: map-get($colors, 'black');
      padding-left: 10px;
      line-height: 22px;
      min-height: 22px;
      max-width: 100%;
      padding-right: 20px;

      @include atContext($multiSelect, '.permDropdown') {
        padding: 0;
      }

      &.placeHolder {
        color: map-get($colors, 'grey');
        font-style: oblique;
      }
    }
  }

  > li.wrapper {
    position: relative;
    width: 100%;
    > ul {
      margin: 0;
      padding: 0;
      max-height: 200px;
      overflow-y: scroll;
      width: calc(100% + 2px);
      position: absolute;
      z-index: 500;
      background-color: map-get($colors, 'white');
      border: 1px solid #{map-get($colors, 'grey-stroke')};
      border-top: none;
      left: -1px;
      box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);
      top: 1px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      display: none;
      @include atContext($multiSelect, '.showOptions') {
        display: initial;
      }

      > li.options {
        min-height: 38px;
        max-height: fit-content;
        text-align: left;
        margin: 5px;
        border-radius: 5px;
        display: flex;
        padding: 10px 5px;
        @include colorHover('white');
        @include atContext($multiSelect, '.permDropdown') {
          align-items: center;
          img {
            margin-right: .33em;
          }
        }
      }

      .row {
        width: 100%;

        .grow {
          flex-grow: 1;
        }

        .button button {
          min-width: 0;
        }
      }

      > li.options {
        &.optionSelected {
          @include colorHover('blue');
          color: map-get($colors, 'white');
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.picture {
  width: 22px;
  height: 22px;
  display: flex;
  position: relative;
}
