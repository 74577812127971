.container.pickTime {
  :global(.SingleDatePickerInput__withBorder) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0;
    width: 100%;
  }
}

.picker {
  display: flex;
  flex-flow: row nowrap;

  :global(.DateInput_input) {
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    border: 0;
    border-radius: 0;
    color: #000;
    font-weight: normal;
  }

  :global(.SingleDatePickerInput__withBorder) {
    border-radius: 5px;
    border: 1px solid #949494;
  }

  .timePicker {
    margin: 0;
    padding: 0;
  }

  :global(.DateInput) {
    width: 110px;
    margin: 8.5px;
  }

  :global(.SingleDatePickerInput__disabled) {
    background-color: #f1f2f2;
    border: 0;
  }

  :global(.DateInput_input__disabled) {
    background: #f1f2f2;
    font-style: normal;
  }

  :global(.DateInput__disabled) {
    background: #f1f2f2;
    color: #dbdbdb;
  }

  .time .textbox {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.time {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;

  .textbox {
    border-radius: 5px;
    width: 61px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .dropdown.dropdown {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0;
    padding: 0;
    min-width: 73px;
    max-width: 73px;
    > li {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

body :global(.SingleDatePicker_picker) {
  z-index: 10000;
}
