$radius: 18px;

.container {
  display: inline-flex;
  flex-flow: row nowrap;

  .search {
    margin: 0px;
    padding: 0px;

    input {
      border: 1px solid #ffffff;
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: $radius 0 0 $radius;
      padding-left: $radius;
    }
  }

  .button {
    margin: 0;
    padding: 0;
    button {
      border: 1px solid #ffffff;
      border-radius: 0px $radius $radius 0px;
      width: 73px;
      min-width: 73px;
      height: 38px;
      min-height: 38px;
      margin-top: 0;
    }
  }
}
