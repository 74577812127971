@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.container {
  height: fit-content;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  $hoverHere: &;
  display: flex;
  align-items: center;

  .removeImage {
    opacity: 0;
    margin-left: 10px;
    img {
      width: 38px;
      height: 38px;
    }

    @include atContext($hoverHere, ':hover') {
      opacity: 1;
    }
  }

  .field {
    flex-grow: 1;
  }

  &.disabled {
    .dragImage, .removeImage {
      display: none;
    }
  }

  &.target {
    background-color: map-get($colors, 'grey-bkgd');
  }
}

.footer {
  border-radius: 0;
  border-top: 1px solid #c4c4c4;
  padding-top: 15px;
  margin-top: 10px;
}


.dragImage {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.modal {
  max-height: 400px;
  overflow-y: auto;
  .options {
    display: flex;
    align-items: center;
    .removeImage {
      height: 25px;
      img {
        height: 25px;
        width: 25px;
      }
    }

    .option {
      flex-grow: 1;
    }
  }
}
