@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.picker {
  height: auto;
  .input {
    margin: 0;
    padding: 0;
    flex-direction: row;

    > input {
      padding: 7.5px;
      width: 100%;
    }

    & + .input,
    & + .period {
      margin-left: 1em;
    }

    > label {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 0.4em;
    }

    > span {
      white-space: nowrap;
      margin-bottom: 0;
      height: $inputHeight;
      line-height: $inputHeight;
      margin-right: 5px;
    }
  }

  .period {
    & + .input,
    & + .period {
      margin-left: 1em;
    }

    display: flex;
    align-items: center;
    > .time {
      max-width: 4em;
      margin-left: 10px;
    }
  }

  :global(.SingleDatePicker .SingleDatePickerInput) {
    border-radius: 5px;
    padding: 1px;
    /* Sorry.  It's !important somewhere else. */
    width: auto !important;

    :global(.DateInput) {
      width: min-content !important;
      input {
        width: 110px !important;
      }
    }
  }
  :global(.SingleDatePicker:not(:last-of-type) .SingleDatePickerInput) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* Sorry.  It's !important somewhere else. */
    border-right-width: 0 !important;
  }
  :global(.SingleDatePicker:not(:first-of-type) .SingleDatePickerInput) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.save.save.save {
  margin-left: 1em;
  margin-top: 0;
  padding: 0;
};
